<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="search"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              density="comfortable"
              solo
              hide-details
              class="rounded-lg"
              color="white"
              style="font-family: Saira Condensed, sans-serif;"></v-text-field>
        </v-col>
        <!-- <v-col class="text-right" cols="12" md="2">
            <v-select
              v-model="currentLeaderboardDate"
              @update:modelValue="dateChange()"
              variant="outlined"
              label="Leaderboard Date"
              density="comfortable"
              hide-details
              class="rounded-lg"
              :items="leaderboardDateData"
              item-title="leaderboardDate"
              item-value="_id"
              return-object
              style="font-family: Saira Condensed, sans-serif;"
            ></v-select>
        </v-col> -->
        <v-col class="text-right" cols="12" md="8">
            <v-btn
            @click="showAddDialog()"
            prepend-icon="mdi-plus"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                Add Leaderboard
            </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card class="dt-container mt-2">    
              <v-data-table
                  :mobile="isMobileOrTablet()"
                  :headers="headers"
                  :items="data"
                  :search="search"
                  :loading="loading"
                  class="text-subtitle-1"
                  style="font-family: Saira Condensed, sans-serif;">
              <template v-slot:loading>
                <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:[`item.leaderboardDates`]="{ item }">
                <span>
                    {{ formatDate(item.startDate) + ' - ' + formatDate(item.endDate) }}
                </span>
              </template>
              <template v-slot:[`item.leaderboardType`]="{ item }">
                <span v-if="item.leaderboardType == 'chicken.gg'">
                    Chicken.GG
                </span>
                <span v-if="item.leaderboardType == 'packdraw'">
                    Packdraw
                </span>
                <span v-if="item.leaderboardType == 'metaspins'">
                    Metaspins
                </span>
                <span v-if="item.leaderboardType == 'clash.gg'">
                    Clash.GG
                </span>
                <span v-if="item.leaderboardType == 'slot'">
                    KOTH Slots
                </span>
                <span v-if="item.leaderboardType == 'koth_sportsbet'">
                    KOTH Sports Bet
                </span>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <span v-if="item.status == 'active'">
                    <v-chip color="blue">
                        Draft
                    </v-chip>
                </span>
                <span v-if="item.status == 'current'">
                    <v-chip color="green">
                        Current
                    </v-chip>
                </span>
                <span v-if="item.status == 'previous'">
                    <v-chip color="gray">
                        Previous
                    </v-chip>
                </span>
              </template>
              <template v-slot:[`item.pointsStatus`]="{ item }">
                <span v-if="item.pointsStatus == 'Not Posted'">
                    <v-chip color="orange">
                        Not Posted
                    </v-chip>
                </span>
                <span v-if="item.pointsStatus == 'Posted'">
                    <v-chip color="blue">
                        Posted
                    </v-chip>
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                    <v-icon v-if="item.leaderboardType != 'slot' && item.leaderboardType != 'koth_sportsbet' && item.pointsStatus == 'Not Posted' && item.status == 'previous'"
                      @click="showPostDialog(item)"
                      class="me-2"
                      size="small"
                    >
                      mdi-clipboard-list
                    </v-icon>
                    <v-icon
                        v-if="item.pointsStatus == 'Posted' && item.status == 'previous'"
                        @click="showViewDialog(item)"
                        class="me-2"
                        size="small"
                    >
                        mdi-clipboard-list-outline
                    </v-icon>
                    <v-icon
                      v-if="item.status != 'previous'"
                      @click="showEditDialog(item)"
                      class="me-2"
                      size="small"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="item.status != 'active' && item.leaderboardType != 'slot' && item.leaderboardType != 'koth_sportsbet'"
                      @click="showLeaderboardUsersDialog(item)"
                      class="me-2"
                      size="small"
                    >
                      mdi-eye
                    </v-icon>
                    <v-icon
                      v-if="item.leaderboardType == 'slot' || item.leaderboardType == 'koth_sportsbet'"
                      @click="showLeaderboardPostUsersDialog(item)"
                      class="me-2"
                      size="small"
                    >
                      mdi-text-box-edit-outline
                    </v-icon>
                    <v-icon 
                      v-if="item.status == 'active'"
                      @click="showDeleteDialog(item)"
                      class="me-2"
                      size="small"
                    >
                      mdi-delete
                    </v-icon>
              </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </v-container>

    <v-dialog v-model="postDialog" width="450" persistent style="font-family: Saira Condensed, sans-serif;">
        <v-card color="primary" rounded="lg" class="pa-2">
            <v-card-text class="font-weight-bold">
                Are you sure you want to post the points for this leaderboard?
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="postDialog = false" :disabled="postLoading" variant="outlined">
                Cancel
            </v-btn>
            <v-btn :loading="postLoading" @click="postItem()" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="450" persistent style="font-family: Saira Condensed, sans-serif;">
        <v-card color="primary" rounded="lg" class="pa-2">
            <v-card-text class="font-weight-bold">
                Are you sure you want to delete this leaderboard?
                <br>
                {{ formatDate(itemToDelete.startDate) }} - {{ formatDate(itemToDelete.endDate) }}
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="deleteDialog = false" :disabled="deleteLoading" variant="outlined">
                Cancel
            </v-btn>
            <v-btn :loading="deleteLoading" @click="deleteLeaderboard()" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>

    <PointsDistributionDialog ref="PointsDistributionDialog"/>
    
    <LeaderboardDialog ref="LeaderboardDialog" @refresh="init()" />

    <LeaderboardUsersDialog ref="LeaderboardUsersDialog" />

    <LeaderboardPostUserDialog ref="LeaderboardPostUserDialog" @refresh="init()" />
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import PointsDistributionDialog from '../Dialogs/Views/PointsDistributionDialog.vue';
import LeaderboardDialog from '../Dialogs/LeaderboardDialog.vue';
import LeaderboardUsersDialog from '../Dialogs/LeaderboardUsersDialog.vue';
import LeaderboardPostUserDialog from '../Dialogs/LeaderboardPostUserDialog.vue';

export default defineComponent({
    components: {
        PointsDistributionDialog,
        LeaderboardDialog,
        LeaderboardUsersDialog,
        LeaderboardPostUserDialog
    },
    data(){
        return {
            data: [],
            leaderboardDateData: [],
            currentLeaderboardDate: [],
            search: null,
            loading: false,
            headers: [
                { title: 'Leaderboard Dates', align: 'start', sortable: true, key: 'leaderboardDates' },
                { title: 'Leaderboard Type', align: 'start', sortable: true, key: 'leaderboardType' },
                { title: 'Type', align: 'start', sortable: true, key: 'type' },
                { title: 'Points Status', align: 'start', sortable: true, key: 'pointsStatus' },
                { title: 'Actions', align: 'end', sortable: false, key: 'action' },
            ],

            snackbar: false,
            snackText: null,

            postDialog: false,
            itemToPost: null,
            postLoading: false,

            deleteDialog: false,
            itemToDelete: null,
            deleteLoading: false
        }
    },
    methods: {
        init(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            if(token){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.leaderboards
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
            else{
                localStorage.removeItem("vuex");
                localStorage.removeItem("ballyboy-admin-accesstoken");
                window.location.reload();
            }
        },
        postItem(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            this.postLoading = true
            const formData = {
                id: this.itemToPost._id
            }
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/points/distribution', formData,
            {
                headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                },
                data: formData
            }
            ).then((res)=>{
                if(res.data){
                    this.init()
                    this.postDialog = false
                    this.postLoading = false

                    this.snackText = 'Points Distributed Succesfully'
                    this.snackbar = true
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    }else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        deleteLeaderboard(){
            this.deleteLoading = true
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.delete(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/' + this.itemToDelete._id,
            { headers:
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res.data){
                    this.init()
                    this.snackText = 'Leaderboard Successfully Deleted!'
                    this.snackbar = true
                    this.deleteLoading = false
                    this.deleteDialog = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    }else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        showLeaderboardUsersDialog(data){
            this.$refs.LeaderboardUsersDialog.init(data)
        },
        showPostDialog(item){
            this.itemToPost = item
            this.postDialog = true
        },
        showDeleteDialog(data){
            this.itemToDelete = data
            this.deleteDialog = true
        },
        showAddDialog(){
            this.$refs.LeaderboardDialog.initAdd()
        },
        showEditDialog(data){
            this.$refs.LeaderboardDialog.initUpdate(data)
        },
        showViewDialog(data){
            this.$refs.PointsDistributionDialog.initView(data)
        },
        showLeaderboardPostUsersDialog(data){
            this.$refs.LeaderboardPostUserDialog.init(data)
        }
    },
    mounted(){
        this.init()
    }
}) 
</script>

<style scoped>

</style>