<template>
    <v-layout style="height: 100vh; width: 100vw;">
      <v-navigation-drawer
        nav
        elevation="2"
        v-model="drawer"
        :rail="rail"
        width="300"
        @click="rail = false"
        color="primary"
        class="pt-4"
      >
       <v-list>
        <v-list-item class="ml-2"
          nav
        >
        <div v-if="rail == false" class="d-flex flex-row justify-start align-self-center">
          <div class="ml-4">
            <v-img  width="50px" height="50px" src="../assets/tab_logo.png"></v-img>
          </div>
          <div class="font-weight-bold text-h6 mt-2 ml-2" style="font-family: Zen Dots, system-ui;">BALLY BOY</div>
        </div>
        <v-img v-else class="ml-n1" src="../assets/tab_logo.png"></v-img>
        
        </v-list-item>
        <!-- <v-list-item v-if="!rail">
            <v-list-item-title>Words to Filipino Sign Language</v-list-item-title>
        </v-list-item> -->
       </v-list>
        
        <v-list :class="rail ? '': 'pl-8'" density="comfortable" color="secondary" nav>
          <v-list-subheader v-if="!rail" class="text-caption" color="white" style="font-family: Zen Dots, system-ui;">MENU</v-list-subheader>
          <v-list-item prepend-icon="mdi-view-dashboard" router :to="'dashboard'" class="mb-5 pa-2">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item prepend-icon="mdi-arm-flex" router :to="'challenges'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Slot Bounties</v-list-item-title>
          </v-list-item>
          <v-list-item prepend-icon="mdi-bulletin-board" router :to="'leaderboards'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Leaderboards</v-list-item-title>
          </v-list-item>
          <v-list-subheader v-if="!rail" class="text-caption" color="white" style="font-family: Zen Dots, system-ui;">Store</v-list-subheader>
          <v-list-item prepend-icon="mdi-bulletin-board" router :to="'points-checker'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Points Checker</v-list-item-title>
          </v-list-item>
          <v-list-item prepend-icon="mdi-storefront" router :to="'products'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Products</v-list-item-title>
          </v-list-item>
          <v-list-item prepend-icon="mdi-cart" router :to="'purchases'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Purchases</v-list-item-title>
          </v-list-item>
          <v-list-subheader v-if="!rail" class="text-caption" color="white" style="font-family: Zen Dots, system-ui;">Giveaways</v-list-subheader>
          <v-list-item prepend-icon="mdi-dharmachakra" router :to="'giveaways'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Giveaways</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item prepend-icon="mdi-party-popper" router :to="'giveaway-winners'" class="mb-5">
             <v-list-item-title class="text-caption" style="font-family: Zen Dots, system-ui;">Giveaway Winners</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-navigation-drawer>
      <v-main class="overflow-auto main-bg">
        <v-app-bar app color="primary" :elevation="4">
          <template v-slot:prepend>
            <v-app-bar-nav-icon @click="drawer ? rail = !rail : rail = false; drawer = true"></v-app-bar-nav-icon>
          </template>
          
          <!-- <v-app-bar-title class="font-weight-medium">{{ $route.meta.title }}</v-app-bar-title> -->
          <template v-slot:append>
          <v-btn
            id="menu-activator"
            bg-color="#5B7459"
            elevation="0"
            rounded="xl"
            height="50"
          >
          <v-avatar>
            <v-img src="../assets/admin-user.png"></v-img>
              <!-- <v-img v-if="!$store.state.user.picture" src="../assets/admin-user.png"></v-img>
              <v-img v-else :src="$store.state.user.picture"></v-img> -->
          </v-avatar>
          <span class="pa-2 blur text-caption" style="font-family: Zen Dots, system-ui;">{{ $store.state.user.username }}</span>
          <!-- <span class="pa-2 blur text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ $store.state.user.username }}</span> -->
          <!-- <span class="pa-2 blur text-subtitle-2">{{ $store.state.user.lname }} {{ $store.state.user.fname[0] }}.</span> -->
          </v-btn>
          <v-menu activator="#menu-activator">
            <v-list bg-color="primary" rounded="lg">
                <v-list-item prepend-icon="mdi-account-edit" @click="showProfile()">
                    <v-list-item-title style="font-family: Saira Condensed, sans-serif;">Profile</v-list-item-title>
                </v-list-item>
                <v-list-item prepend-icon="mdi-logout" @click="logout()">
                    <v-list-item-title style="font-family: Saira Condensed, sans-serif;">Log out</v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu>
        </template>
        </v-app-bar>
            <div class="pt-6 px-4">
              <h2 style="font-family: Saira Condensed, sans-serif;">{{ $route.meta.title }}</h2>
              <span class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">{{ currentTime }}</span>
            </div>
          <router-view/>
      </v-main>
    </v-layout>
    

    <ProfileDialog ref="ProfileDialog" v-on:logout="logout()"/>
</template>

<script>
import ProfileDialog from '@/components/Dialogs/ProfileDialog.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainView',
  components: {
     ProfileDialog
  },
  data () {
      return {
        drawer: true,
        rail: false,
        userType: '',
        currentTime: this.getCurrentDate()
      }
    },
    methods: {
        logout(){
            localStorage.removeItem("vuex");
            localStorage.removeItem("ballyboy-admin-accesstoken");
            window.location.reload()
        },
        loadMenu(userType) {
          switch (userType) {
            case 1:
              this.userType = '/admin'
              break; 
          }
        },
        getCurrentDate(){
          const cur_date = new Date();
          const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
          const day = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
          return day[cur_date.getDay()] + ' | ' + month[cur_date.getMonth()] + ' ' + cur_date.getDate() + ', '+ cur_date.getFullYear() + ' | ' + this.formatAMPM(cur_date);
        },
        formatAMPM(date) {
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var seconds = date.getSeconds();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? '0'+minutes : minutes;
          seconds = seconds < 10 ? '0'+seconds : seconds;
          var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
          return strTime;
        },
        showProfile(){
          this.$refs.ProfileDialog.initView()
        }
    },
    mounted() {
      // this.loadMenu(this.$store.state.user.type)
      setInterval(() => {
        this.currentTime = this.getCurrentDate();
      }, 1000);
      const isTabletOrPhone = window.matchMedia("(max-width: 1024px)").matches;
        if (isTabletOrPhone) {
           this.drawer = false
        } else {
            this.drawer = true
        }
    }
});
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');

@media (max-width: 767px) {
  .blur {
    display: none;
  }
}

.hide-scrollbar {
  /* Hide scrollbar for WebKit browsers (e.g., Chrome, Safari) */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.main-bg {
	background-image: url('../assets/hero-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
  height: 100vh;
}
</style>
