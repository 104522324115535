<template>
    <v-dialog v-model="dialog" fullscreen>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 0px);">
                <v-form ref="form" class="mt-4" style="font-family: Saira Condensed, sans-serif;">
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Dates:</div>
                            <div>{{ formatDate(leaderboardDetails.startDate) + ' - ' + formatDate(leaderboardDetails.endDate)  }}</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Type:</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'metaspins'">Metaspins</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'packdraw'">Packdraw</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'chicken.gg'">Chicken.GG</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'clash.gg'">Clash.GG</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'slot'">KOTH Slots</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'koth_sportsbet'">KOTH Sports Bet</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Status:</div>
                            <div v-if="leaderboardDetails.status == 'active'">Active</div>
                            <div v-if="leaderboardDetails.status == 'current'">Current</div>
                            <div v-if="leaderboardDetails.status == 'previous'">Previous</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Created At:</div>
                            <div>{{ formatDateTime(leaderboardDetails.createdAt) }}</div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                            :disabled="leaderboardDetails.status == 'previous'"
                            @click="showAddDialog(leaderboardDetails)"
                            prepend-icon="mdi-plus"
                            class="mt-2 rounded-lg"
                            color="primary"
                            height="40px"
                            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                                Add Users on Leaderboard
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="dt-container mt-2">    
                                <v-data-table
                                    :mobile="isMobileOrTablet()"
                                    :disable-sort="false"
                                    :headers="headers"
                                    :items="data"
                                    :loading="loading"
                                    class="text-subtitle-1"
                                    style="font-family: Saira Condensed, sans-serif;">
                                <template v-slot:loading>
                                    <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                                </template>
                                <template v-slot:[`item.rank`]="{ index }">
                                    <span>{{ index + 1 }}</span>
                                </template>
                                <template v-slot:[`item.createdAt`]="{ item }">
                                        <span>{{ formatDateTime(item.createdAt) }}</span>
                                </template>
                                <template v-slot:[`item.multi`]="{ item }">
                                        <span>{{ item.multi }}x</span>
                                </template>
                                <template v-slot:[`item.wagerAmount`]="{ item }">
                                    <span>$ {{ item.wagerAmount.toFixed(2) }}</span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                        <v-icon
                                            @click="showEditDialog(item)"
                                            class="me-2"
                                            size="small"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            @click="showDeleteDialog(item)"
                                            class="me-2"
                                            size="small"
                                            >
                                            mdi-delete
                                        </v-icon>
                                </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>

        <v-dialog v-model="deleteUserDialog" width="450" persistent style="font-family: Saira Condensed, sans-serif;">
            <v-card color="primary" rounded="lg" class="pa-2">
                <v-card-text class="font-weight-bold">
                    Are you sure you want to delete this user on the leaderboard?
                </v-card-text>
                <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="deleteUserDialog = false" :disabled="loadingUserDelete" variant="outlined">
                    Cancel
                </v-btn>
                <v-btn :loading="loadingUserDelete" @click="deleteLeaderboardUser()" color="red" variant="elevated">
                    Confirm
                </v-btn>
                </template>
            </v-card>
        </v-dialog>

    </v-dialog>

    <LeaderboardManualUsersDialog ref="LeaderboardManualUsersDialog" @refresh="initUsers()" />
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import LeaderboardManualUsersDialog from './LeaderboardManualUsersDialog.vue';


export default defineComponent({
     emits: ["refresh"],
     components: {
        LeaderboardManualUsersDialog
     },
     data(){
        return {
            dialog: false,
            title: null,
            sub_title: null,
            action: null,
            
            snackbar: false,
            snackText: null,

            data: [],
            search: null,
            leaderboardDetails: null,
            loading: false,

            headers: [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Username', align: 'start', sortable: true, key: 'username' },
                { title: 'Multi', align: 'start', sortable: true, key: 'multi' },
                { title: 'Bet ID', align: 'start', sortable: true, key: 'betId' },
                { title: 'Created At', align: 'start', sortable: true, key: 'createdAt' },
                { title: 'Actions', align: 'end', sortable: false, key: 'action' },
            ],

            deleteUserDialog: false,
            userToDelete: [],
            loadingUserDelete: false
        }
     },
     methods: {
        init(data){
            this.dialog = true
            this.title = 'View Leaderboard Users'
            this.sub_title = 'View users Ballyboy Leaderboard'

            this.leaderboardDetails = data
            this.initUsers()
        },
        initUsers(){
            this.loading = true
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/user?leaderboardId=' + this.leaderboardDetails._id,
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res){
                    this.data = res.data.leaderboardUsers
                    this.loading = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        deleteLeaderboardUser(){
            this.loadingUserDelete = true
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.delete(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/user/' + this.userToDelete._id,
            { headers:
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res.data){
                    this.initUsers()
                    this.deleteUserDialog = false
                    this.loadingUserDelete = false
                }
            })
        },
        showAddDialog(data){
            this.$refs.LeaderboardManualUsersDialog.initAdd(data)
        },
        showEditDialog(data){
            this.$refs.LeaderboardManualUsersDialog.initUpdate(data)
        },
        showDeleteDialog(data){
            this.userToDelete = data
            this.deleteUserDialog = true
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>