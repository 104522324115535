<template>
    <v-dialog v-model="dialog" fullscreen persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 100px);">
                <v-form ref="form" class="mt-4" style="font-family: Saira Condensed, sans-serif;">
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Dates:</div>
                            <div>{{ formatDate(leaderboardDetails.startDate) + ' - ' + formatDate(leaderboardDetails.endDate)  }}</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Leaderboard Type:</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'metaspins'">Metaspins</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'packdraw'">Packdraw</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'chicken.gg'">Chicken.GG</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'clash.gg'">Clash.GG</div>
                            <div v-if="leaderboardDetails.leaderboardType == 'slot'">Slot Challenges</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Status:</div>
                            <div v-if="leaderboardDetails.status == 'active'">Draft</div>
                            <div v-if="leaderboardDetails.status == 'current'">Current</div>
                            <div v-if="leaderboardDetails.status == 'previous'">Previous</div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <div class="font-weight-bold">Created At:</div>
                            <div>{{ formatDateTime(leaderboardDetails.createdAt) }}</div>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-btn
                            v-if="leaderboardDetails.leaderboardType == 'slot'"
                            @click="showAddDialog(leaderboardDetails)"
                            prepend-icon="mdi-plus"
                            class="mt-2 rounded-lg"
                            color="primary"
                            height="40px"
                            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                                Add Users on Leaderboard
                            </v-btn>
                        </v-col> -->
                        <v-col cols="12">
                            <v-card class="dt-container mt-2">    
                                <v-data-table
                                    :mobile="isMobileOrTablet()"
                                    :disable-sort="false"
                                    :headers="headers"
                                    :items="data"
                                    :loading="loading"
                                    class="text-subtitle-1"
                                    style="font-family: Saira Condensed, sans-serif;">
                                <template v-slot:loading>
                                    <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                                </template>
                                <template v-slot:[`item.rank`]="{ index }">
                                    <span>{{ index + 1 }}</span>
                                </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
    </v-dialog>

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     components: {
        
     },
     data(){
        return {
            dialog: false,
            title: null,
            sub_title: null,
            action: null,
            
            snackbar: false,
            snackText: null,

            data: [],
            search: null,
            leaderboardDetails: null,
            loading: false,

            headers: [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Username', align: 'start', sortable: true, key: 'username' },
                { title: 'Wager Amount', align: 'end', sortable: true, key: 'wagerAmount' },
            ],

            clashGGHeader:  [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Username', align: 'start', sortable: true, key: 'name' },
                { title: 'Wager Amount', align: 'end', sortable: true, key: 'wagered' },
            ],

            metaspinsHeader: [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Metaspins Player Name', align: 'start', sortable: true, key: 'username' },
                { title: 'Metaspins Player ID', align: 'start', sortable: true, key: 'playerId' },
                { title: 'Waget Amount', align: 'end', sortable: true, key: 'bets' },
            ],

            packdrawHeader: [
                { title: 'Rank', align: 'start', sortable: true, key: 'rank' },
                { title: 'Display Name', align: 'start', sortable: true, key: 'username' },
                { title: 'Wager Amount', align: 'end', sortable: true, key: 'wagerAmount' },
            ],

            deleteUserDialog: false,
            userToDelete: [],
            loadingUserDelete: false
        }
     },
     methods: {
        init(data){
            this.dialog = true
            this.title = 'View Leaderboard Users'
            this.sub_title = 'View users Ballyboy Leaderboard'

            this.leaderboardDetails = data
            this.data = data.leaderboardData

            if (data.leaderboardType == 'metaspins'){
                this.headers = this.metaspinsHeader
            }
            else if(data.leaderboardType == 'clash.gg'){
                this.headers = this.clashGGHeader
            }
            else if (data.leaderboardType == 'packdraw'){
                this.headers = this.packdrawHeader
            }
        },
        closeDialog(){
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>