<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading"  flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense>
                        <v-col cols="12" class="mb-2 d-flex">
                            <p class="text-subtitle-2 font-weight-bold">Note:&nbsp;</p> <p class="text-subtitle-2">Start Date and End Date set will be all inclusive</p>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="startDate"
                                :rules="[formRules.required]"
                                label="Start Date"
                                type="date"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="endDate"
                                :rules="[formRules.required]"
                                label="End Date"
                                type="date"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="action == 'edit'" cols="12" md="6">
                            <v-select
                                v-model="status"
                                label="Status"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                                :items="statusTypes"
                                item-title="name"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="leaderboardType"
                                label="Leaderboard Type"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                                :items="leaderboardTypes"
                                item-title="name"
                                item-value="value"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,
            
            snackbar: false,
            snackText: null,

            id: null,
            startDate: null,
            endDate: null,
            leaderboardType: null,
            status: null,

            statusTypes: [
                { name: 'Active', value: 'active' },
                { name: 'Current', value: 'current' },
                { name: 'Previous', value: 'previous' }
            ],

            leaderboardTypes: [
                { name: 'Metapins', value: 'metaspins' },
                { name: 'Packdraw', value: 'packdraw' },
                { name: 'Clash.GG', value: 'clash.gg' },
                { name: 'KOTH Slots', value: 'slot' },
                { name: 'KOTH Sports Bet', value: 'koth_sportsbet' }
            ]
        }
     },
     methods: {
        initAdd(){
            this.dialog = true
            this.main_title = 'Add Leaderboard'
            this.sub_title = 'Ballyboy leaderboard and information'
            this.action = 'add'
        },
        initUpdate(data){
            this.dialog = true
            this.main_title = 'Edit Leaderboard'
            this.sub_title = 'Ballyboy leaderboard and information'
            this.action = 'edit'

            this.id = data._id
            this.startDate = data.startDate
            this.endDate = data.endDate
            this.leaderboardType = data.leaderboardType
            this.status = data.status
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                this.loading = true
                const token = localStorage.getItem('ballyboy-admin-accesstoken')

                let formData = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    leaderboardType: this.leaderboardType,
                    status: this.status
                }

                if(this.action == 'add'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/create', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.snackText = 'Leaderboard Successfully Added!'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.loading = false
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                else if(this.action == 'edit'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/' + this.id + '/update ', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.snackText = 'Leaderboard Successfully Updated!'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.loading = false
                            this.dialog = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            } 
                            else if(error.response.status == 400){
                                this.snackText = error.response.data.message
                                this.snackbar = true
                                this.loading = false
                            }
                            else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>